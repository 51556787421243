import exportFile from 'quasar/src/utils/export-file.js';
import ExcelJS from 'exceljs'

import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

import { Toast } from '@/tools'

export const wrapToCSV = (val, formatFn) => {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : formatted

  return formatted
}

export const exportToCSV = ({ header, data, filename }) => {
  const { showToast } = Toast()

  const content = [header.map(col => wrapToCSV(col.label))]
    .concat(
      data.map(row =>
        header
          .map(col => {
            return wrapToCSV(
              typeof col.field === 'function'
                ? col.field(row)
                : row[col.field === void 0 ? col.name : col.field]
                    .toString()
                    .replace(/,/g, ''),
              col.format
            )
          })
          .join(',')
      )
    )
    .join('\r\n')

  const status = exportFile(`${filename}.csv`, content, 'text/csv')

  if (status !== true) {
    showToast('Browser denied file download...', 'warning')
  }
}

export const exportToPDF = ({ header, data, filename, id, graph }) => {
  const doc = new jsPDF()
  let canvasImg = ''

  if (graph) {
    //Convert svg to png
    const svgString = new XMLSerializer().serializeToString(
      document.querySelector(`#${id}`)
    )

    const canvas = document.createElement('canvas')
    canvas.width = 656
    canvas.height = 382
    const ctx = canvas.getContext('2d')
    const DOMURL = self.URL || self.webkitURL || self
    const img = new Image()
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
    const url = DOMURL.createObjectURL(svg)
    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      canvasImg = canvas.toDataURL('image/png', '1.0')
    }
    img.src = url
  }

  //Generate Table
  const headers = header.map(item => ({
    header: item.label,
    dataKey: item.name
  }))

  setTimeout(() => {
    doc.addImage(canvasImg, 'PNG', 10, 10)

    doc.autoTable({
      startY: graph ? 120 : 15,
      columns: headers,
      body: data
    })

    //Page number
    const pages = doc.internal.getNumberOfPages()
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    doc.setFontSize(10)

    for (let i = 1; i < pages + 1; i++) {
      let horizontalPos = pageWidth / 2
      let verticalPos = pageHeight - 10
      doc.setPage(i)
      doc.text(`${i} of ${pages}`, horizontalPos, verticalPos, {
        align: 'center'
      })
    }

    doc.save(`${filename}.pdf`)
  }, 1000)
}

export const getImageDimensions = file => {
  return new Promise(function (resolved) {
    var i = new Image()
    i.onload = function () {
      resolved({ w: i.naturalWidth, h: i.naturalHeight })
    }
    i.src = file
  })
}

export const exportToExcel = ({
  header,
  data,
  filename,
  sheetName,
  chart,
  chart2,
  position,
  chartName,
  chartName2
}) => {
  async function exportData() {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(sheetName ?? 'Sheet 1')

    // Header
    const headers = header
      .filter(item => item?.name !== 'action')
      .map(item => ({ name: item?.label, key: item?.name }))

    // Content
    const content = data.map(row =>
      headers.map(col => {
        return row[col.key]
      })
    )

    if (chart) {
      const image = await getImageDimensions(chart)

      const imageId = workbook.addImage({
        base64: chart,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 2 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      if (chartName) {
        worksheet.getCell('A1').value = chartName
        worksheet.getCell('A1').font = {
          bold: true
        }
      }
    }

    if (chart2) {
      const image = await getImageDimensions(chart2)

      const imageId = workbook.addImage({
        base64: chart2,
        extension: 'png'
      })

      worksheet.addImage(imageId, {
        tl: { col: position?.chart?.col + 3, row: 2 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      if (chartName2) {
        worksheet.getColumn(position?.chart?.col + 4).header = chartName2
        worksheet.getColumn(position?.chart?.col + 4).font = {
          bold: true
        }
      }
    }

    worksheet.addTable({
      name: 'Table',
      ref: `A${position?.table ? position.table + 5 : 1}`,
      style: {
        showRowStripes: true
      },
      columns: headers,
      rows: content
    })

    // Export file
    await workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const anchor = document.createElement('a')
      const url = URL.createObjectURL(blob)

      anchor.href = url
      anchor.download = `${filename}.xlsx`
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(url)
    })
  }

  return exportData()
}

export const exportDashboardToExcel = ({
  widget,
  sales,
  customersP30,
  customersP90,
  basketSize,
  frequency,
  basketLines,
  categorySales,
  brandSales,
  SKUSales,
  storeSales
}) => {
  async function exportData() {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Dashboard')

    // Styles
    const headerStyle = { bold: true, size: 14 }
    const subHeaderStyle = { bold: true, size: 14 }

    // Header
    worksheet.getCell('A1').value = 'Dashboard'
    worksheet.getCell('A1').font = headerStyle

    // Widgets
    const headerWidgets = [
      { name: ' ', key: 'name', width: 25 },
      { name: '  ', key: 'value', width: 15 },
      { name: '   ', key: 'subValue', width: 25 }
    ]

    worksheet.addTable({
      name: 'Widgets',
      ref: 'A3',
      style: {
        showRowStripes: true,
        showFirstColumn: true
      },
      columns: headerWidgets,
      rows: widget?.map(row =>
        headerWidgets.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    worksheet.columns.forEach((column, index) => {
      column.width = headerWidgets[index].width
    })

    // ------------------------------------------------------------------
    // By Month Sales YTD
    const salesRow = worksheet._nextRow + 2
    worksheet.getCell('A' + salesRow).value = 'By Month Sales YTD'
    worksheet.getCell('A' + salesRow).font = subHeaderStyle

    const headerSales = [
      { name: 'Date', key: 'name', width: 25 },
      {
        name: 'Selected Year',
        key: 'value',
        width: 15
      },
      {
        name: 'Year Ago',
        key: 'value2',
        width: 25
      }
    ]

    worksheet.addTable({
      name: 'Sales',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: sales?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // By Month Customers (P30) YTD
    const customerP30Row = worksheet._nextRow + 2
    worksheet.getCell('A' + customerP30Row).value =
      'By Month Customers (P30) YTD'
    worksheet.getCell('A' + customerP30Row).font = subHeaderStyle

    worksheet.addTable({
      name: 'CustomersP30',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: customersP30?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // By Month Customers (P90) YTD
    const customerP90Row = worksheet._nextRow + 2
    worksheet.getCell('A' + customerP90Row).value =
      'By Month Customers (P90) YTD'
    worksheet.getCell('A' + customerP90Row).font = subHeaderStyle

    worksheet.addTable({
      name: 'CustomersP90',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: customersP90?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // By Month Average Basket Size
    const basketSizeRow = worksheet._nextRow + 2
    worksheet.getCell('A' + basketSizeRow).value =
      'By Month Average Basket Size'
    worksheet.getCell('A' + basketSizeRow).font = subHeaderStyle

    worksheet.addTable({
      name: 'AverageBasketSize',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: basketSize?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // By Month Average Frequency
    const frequencyRow = worksheet._nextRow + 2
    worksheet.getCell('A' + frequencyRow).value = 'By Month Average Frequency'
    worksheet.getCell('A' + frequencyRow).font = subHeaderStyle

    worksheet.addTable({
      name: 'AverageFrequency',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: frequency?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // By Month Lines per Basket
    const basketLinesRow = worksheet._nextRow + 2
    worksheet.getCell('A' + basketLinesRow).value = 'By Month Lines per Basket'
    worksheet.getCell('A' + basketLinesRow).font = subHeaderStyle

    worksheet.addTable({
      name: 'BasketeLines',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSales,
      rows: basketLines?.map(row =>
        headerSales.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // Top Category Sales (Top 5)
    const categoryRow = worksheet._nextRow + 2
    worksheet.getCell('A' + categoryRow).value = 'Top Category Sales (Top 5)'
    worksheet.getCell('A' + categoryRow).font = subHeaderStyle

    const headerCategory = [
      { name: 'Category', key: 'name', width: 25 },
      { name: 'Sales', key: 'value', width: 15 }
    ]

    worksheet.addTable({
      name: 'TopCategories',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerCategory,
      rows: categorySales?.map(row =>
        headerCategory.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // Top Brand Sales (Top 5)
    const brandRow = worksheet._nextRow + 2
    worksheet.getCell('A' + brandRow).value = 'Top Brand Sales (Top 5)'
    worksheet.getCell('A' + brandRow).font = subHeaderStyle

    const headerBrand = [
      { name: 'Brand', key: 'name', width: 25 },
      { name: 'Sales', key: 'value', width: 15 }
    ]

    worksheet.addTable({
      name: 'TopBrands',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerBrand,
      rows: brandSales?.map(row =>
        headerBrand.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // Top SKU Sales (Top 10)
    const SKURow = worksheet._nextRow + 2
    worksheet.getCell('A' + SKURow).value = 'Top SKU Sales (Top 10)'
    worksheet.getCell('A' + SKURow).font = subHeaderStyle

    const headerSKU = [
      { name: 'SKU', key: 'name', width: 25 },
      { name: 'Sales', key: 'value', width: 15 }
    ]

    worksheet.addTable({
      name: 'TopSKUs',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerSKU,
      rows: SKUSales?.map(row =>
        headerSKU.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // Top Store Sales (Top 10)
    const StoreRow = worksheet._nextRow + 2
    worksheet.getCell('A' + StoreRow).value = 'Top Store Sales (Top 10)'
    worksheet.getCell('A' + StoreRow).font = subHeaderStyle

    const headerStore = [
      { name: 'Store', key: 'name', width: 25 },
      { name: 'Sales', key: 'value', width: 15 }
    ]

    worksheet.addTable({
      name: 'TopStores',
      ref: 'A' + worksheet._nextRow,
      style: {
        showRowStripes: true
      },
      columns: headerStore,
      rows: storeSales?.map(row =>
        headerStore.map(col => {
          return row[col.key]
        })
      ) ?? [['No data']]
    })

    // ------------------------------------------------------------------
    // Export file
    await workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const anchor = document.createElement('a')
      const url = URL.createObjectURL(blob)

      anchor.href = url
      anchor.download = 'dashboard.xlsx'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(url)
    })
  }

  return exportData()
}

export const exportMonthlyMetricsToExcel = ({
  header,
  data,
  filename,
  sheetName,
  chart,
  chart2,
  chart3,
  chart4,
  chart5,
  chart6,
  position
}) => {
  async function exportData() {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(sheetName ?? 'Sheet 1')

    // Header
    const headers = header
      .filter(item => item?.name !== 'action')
      .map(item => ({ name: item?.label, key: item?.name }))

    // Content
    const content = data.map(row =>
      headers.map(col => {
        return row[col.key]
      })
    )

    if (chart) {
      const image = await getImageDimensions(chart)

      const imageId = workbook.addImage({
        base64: chart,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 2 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('A1').value = 'Sales Trend'
      worksheet.getCell('A1').font = {
        bold: true
      }
    }

    if (chart2) {
      const image = await getImageDimensions(chart2)

      const imageId = workbook.addImage({
        base64: chart2,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 10, row: 2 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('K1').value = 'Transaction Count Trend'
      worksheet.getCell('K1').font = {
        bold: true
      }
    }

    if (chart3) {
      const image = await getImageDimensions(chart3)

      const imageId = workbook.addImage({
        base64: chart3,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 24 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('A23').value = 'Basket Size Trend'
      worksheet.getCell('A23').font = {
        bold: true
      }
    }

    if (chart4) {
      const image = await getImageDimensions(chart4)

      const imageId = workbook.addImage({
        base64: chart4,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 10, row: 24 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('K23').value = 'Frequency Trend'
      worksheet.getCell('K23').font = {
        bold: true
      }
    }

    if (chart5) {
      const image = await getImageDimensions(chart5)

      const imageId = workbook.addImage({
        base64: chart5,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 46 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('A45').value = 'Unique Customers Trend'
      worksheet.getCell('A45').font = {
        bold: true
      }
    }

    if (chart6) {
      const image = await getImageDimensions(chart6)

      const imageId = workbook.addImage({
        base64: chart6,
        extension: 'png'
      })
      worksheet.addImage(imageId, {
        tl: { col: 10, row: 46 },
        ext: { width: image.w, height: image.h },
        editAs: 'absolute'
      })

      worksheet.getCell('K45').value = 'Unique SKUs Trend'
      worksheet.getCell('K45').font = {
        bold: true
      }
    }

    worksheet.addTable({
      name: 'Table',
      ref: `A${position?.table ? position.table + 5 : 1}`,
      style: {
        showRowStripes: true
      },
      columns: headers,
      rows: content
    })

    // Export file
    await workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const anchor = document.createElement('a')
      const url = URL.createObjectURL(blob)

      anchor.href = url
      anchor.download = `${filename}.xlsx`
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      URL.revokeObjectURL(url)
    })
  }

  return exportData()
}
