<template>
  <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
    {{ text }}
  </q-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
