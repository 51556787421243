<template>
  <q-header bordered>
    <q-toolbar>
      <q-btn flat @click="toggleSidebar" round dense icon="menu" />
      <q-space />

      <div>
        <q-btn-dropdown
          class="text-capitalize withoutIcon"
          flat
          stretch
          v-if="
            (isPrincipal || isRetailer) &&
            (!loadingPrincipals || !loadingRetailers)
          "
        >
          <template v-slot:label>
            <div class="row items-start no-wrap">
              <q-icon left name="home" />
              <div class="text-xs text-gray text-left">
                <div class="font-semibold text-secondary">
                  Default
                  {{
                    isPrincipal ? 'Retailer' : isRetailer ? 'Principal' : ''
                  }}:
                </div>
                <Badge :text="defaultStore.name" />
              </div>
            </div>
          </template>
          <q-list class="text-primary q-my-sm">
            <q-item
              v-for="list in defaultStoreLists"
              :key="list.name"
              clickable
              dense
              v-close-popup
              @click="onSelectStore(list)"
            >
              <q-item-section>
                <q-item-label class="q-mr-md">{{ list.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <Tooltip
          :text="`Change Default ${
            isPrincipal ? 'Retailer' : isRetailer ? 'Principal' : ''
          }`"
        />
      </div>

      <q-btn-dropdown
        stretch
        flat
        :label="profile ? `${profile.first_name} ${profile.last_name}` : ''"
        icon="person"
        class="text-capitalize"
      >
        <q-list class="text-primary q-my-sm">
          <q-item
            v-for="list in navMenuLists"
            :key="list.name"
            clickable
            dense
            v-close-popup
            router
            :to="list.url"
          >
            <q-avatar :icon="list.icon" size="lg" />
            <q-item-section>
              <q-item-label class="q-mr-md">{{ list.name }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator inset spaced />
          <q-item clickable dense v-close-popup @click="onLogout">
            <q-avatar icon="logout" size="lg" />
            <q-item-section>
              <q-item-label>Logout</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-toolbar>
  </q-header>

  <Sidebar :sidebarOpen="sidebar" />
</template>

<script>
import { ref, computed, inject, watch, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

import Sidebar from '@/components/Sidebar'
import Badge from '@/components/Badge'
import Tooltip from '@/components/Tooltip'

import { Toast, fetchData } from '@/tools'

import store from '@/store'

export default {
  components: { Sidebar, Badge, Tooltip },
  setup() {
    const router = useRouter()
    const { showToast } = Toast()
    const state = inject('store')?.state

    const {
      data: dataPrincipals,
      error: errorPrincipals,
      loading: loadingPrincipals,
      get: getPrincipals
    } = fetchData()

    const {
      data: dataRetailers,
      error: errorRetailers,
      loading: loadingRetailers,
      get: getRetailers
    } = fetchData()

    const defaultID = ref(state?.defaultID ?? state?.profile?.principal_id)
    const isPrincipal = ref(state?.accountType?.principal)
    const isRetailer = ref(state?.accountType?.retailer)
    const sidebar = ref(false)
    const isAdmin = ref(state?.isAdmin)
    const profile = ref(null)
    const principals = ref([])
    const retailers = ref([])
    const navMenuLists = ref([])
    const defaultMenuLists = ref([
      {
        name: 'Change Password',
        icon: 'key',
        url: '/maintenance/change-password'
      }
    ])

    const defaultStoreLists = computed(() => {
      return isRetailer.value
        ? principals.value
        : isPrincipal.value
        ? retailers.value
        : []
    })

    const defaultStore = computed(() => {
      const store = isRetailer.value
        ? principals.value
        : isPrincipal.value
        ? retailers.value
        : []

      return {
        id: defaultID.value,
        name:
          store.filter(item => item.id == defaultID.value)[0]?.name ??
          state?.profile?.retailer_access[0]?.name
      }
    })

    const toggleSidebar = () => {
      sidebar.value = !sidebar.value
    }

    const onLogout = () => {
      const route = router.resolve({
        name: 'login'
      })

      localStorage.removeItem('token')
      localStorage.removeItem('defaultID')
      window.location.assign(route.href)
    }

    const onSelectStore = data => {
      const messageType = isRetailer.value
        ? 'Principal'
        : isPrincipal.value
        ? 'Retailer'
        : ''

      defaultID.value = data?.id
      store.methods.updateDefaultID(data?.id)
      localStorage.setItem('defaultID', data?.id)

      showToast(
        `Default ${messageType} changed to <strong>${data?.name}</strong>`,
        'success'
      )
    }

    const fetchInitialData = () => {
      if (isRetailer.value) {
        getPrincipals('v1/principals')
      } else if (isPrincipal.value) {
        getRetailers('v1/retailers')
      }
    }

    onBeforeMount(() => {
      fetchInitialData()

      if (state?.profile) {
        profile.value = state.profile
      }

      if (state?.menus) {
        state?.menus
          ?.filter(item => {
            if (item.menu_tag === 'N') {
              return true
            }
            return false
          })
          .map(item => {
            return {
              name: item?.name,
              icon: item?.icon ?? 'settings',
              url: `/${item?.url}`
            }
          })

        if (isAdmin.value) {
          navMenuLists.value.unshift({
            name: 'Users',
            icon: 'o_people',
            url: '/maintenance/users'
          })
        }

        defaultMenuLists.value.forEach(item => navMenuLists.value.unshift(item))
      }

      watch([dataPrincipals, errorPrincipals, loadingPrincipals], () => {
        if (!loadingPrincipals.value) {
          if (errorPrincipals?.value) {
            showToast('There was a problem fetching principals.', 'danger')
          } else if (dataPrincipals?.value) {
            principals.value = dataPrincipals?.value
          }
        }
      })

      watch([dataRetailers, errorRetailers, loadingRetailers], () => {
        if (!loadingRetailers.value) {
          if (errorRetailers?.value) {
            showToast('There was a problem fetching retailers.', 'danger')
          } else if (dataRetailers?.value) {
            retailers.value = dataRetailers?.value
          }
        }
      })
    })

    return {
      loadingPrincipals,
      loadingRetailers,
      isPrincipal,
      isRetailer,
      sidebar,
      navMenuLists,
      profile,
      principals,
      retailers,
      defaultStoreLists,
      defaultStore,
      toggleSidebar,
      onLogout,
      onSelectStore
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.withoutIcon) {
  .q-btn-dropdown__arrow {
    @apply hidden;
  }
}
</style>
