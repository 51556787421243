export const toCurrency = number => {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  }).format(number)
}

export const toNumber = number => {
  return Intl.NumberFormat('en-US').format(toFixedNumber(number))
}

export const toMetricNumber = num => {
  if (num >= 1000 && num < 1000000) {
    return toFixedNumber(Math.round(num) / 1000) + 'K'
  } else if (num >= 1000000 && num < 1000000000) {
    return toFixedNumber(Math.round(num) / 1000000) + 'M'
  } else if (num >= 1000000000) {
    return toFixedNumber(Math.round(num) / 1000000000) + 'B'
  } else {
    return toFixedNumber(num)
  }
}

export const toPercent = num => {
  return `${toFixedNumber(num)}%`
}

export const toFixedNumber = num => {
  return parseFloat(num?.toFixed(2))
}

export const capitalizeEachWord = data => {
  const arr = data.split(' ')

  arr.forEach((item, index) => {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1)
  })

  return arr.join(' ')
}

export const svg2img = (id, width, height, callback) => {
  const svg = document.querySelector(`#${id}`)
  const xml = new XMLSerializer().serializeToString(svg)
  const svg64 = btoa(unescape(encodeURIComponent(xml)))
  const b64start = 'data:image/svg+xml;base64,'
  const image64 = b64start + svg64
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  canvas.width = width
  canvas.height = height

  const image = new Image()

  image.onload = function () {
    context.clearRect(0, 0, width, height)
    context.drawImage(image, 0, 0, width, height)

    const pngData = canvas.toDataURL('image/png')

    callback(pngData)
  }

  image.src = image64
}
