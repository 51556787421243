<template>
  <Landing loading v-if="isLoading" />
  <q-layout class="pageContainer" container v-else>
    <Navbar v-if="isAuthenticated" />

    <router-view v-slot="{ Component }" v-if="isAuthenticated">
      <transition appear enter-active-class="animated animate__fadeIn">
        <q-page-container :key="$route.fullPath" class="pageContent">
          <div class="q-ma-lg">
            <Landing
              loading
              v-if="isLoadingAllowed"
              :style="{ height: 'calc(100vh - 200px)' }"
            />
            <PageNotFound v-else-if="!isAllowed" />
            <component :is="Component" v-else />
          </div>

          <q-page-scroller
            class="relative z-10"
            position="bottom-right"
            :scroll-offset="150"
            :offset="[18, 18]"
          >
            <q-btn fab-mini icon="keyboard_arrow_up" color="accent" />
          </q-page-scroller>
        </q-page-container>
      </transition>
    </router-view>

    <router-view v-slot="{ Component }" v-else>
      <transition appear enter-active-class="animated animate__fadeIn">
        <component :is="Component" />
      </transition>

      <div
        class="p-4 fixed bottom-0 left-0 right-0 flex items-center justify-center gap-2 text-white"
      >
        <span>© 2022 </span>
        <a
          class="text-info hover:underline"
          href="https://www.iripple.com/"
          target="_blank"
        >
          iRipple, Inc.</a
        >
      </div>
    </router-view>
  </q-layout>
</template>

<script>
import { ref, provide, onBeforeMount, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Notify } from 'quasar'
import dayjs from 'dayjs'

import Navbar from '@/components/Navbar'
import Landing from '@/components/pages/Landing'
import PageNotFound from '@/components/pages/PageNotFound'
import Modal from '@/components/Modal'

import store from '@/store'
import { Toast, fetchData } from '@/tools'
import { isAdminAccess, excludedPaths } from '@/constants'

export default {
  components: { Navbar, Landing, PageNotFound, Modal },
  setup() {
    const router = useRouter()
    const { showToast } = Toast()
    const { data, error, loading, get } = fetchData()

    const isLoading = ref(true)
    const isLoadingAllowed = ref(true)
    const isAuthenticated = ref(localStorage.getItem('token'))
    const isAllowed = ref(null)
    const remainingDate = ref(0)

    const showNotif = () => {
      Notify.create({
        icon: 'info',
        color: 'info',
        message: `<p>
                  Your password will expire on
                  <strong>${remainingDate.value} day(s)</strong>.
                </p>
                <p>
                  Please consider changing your password before it expires to avoid
                  problem logging in.
                </p>`,
        position: 'top-right',
        textColor: 'black',
        timeout: 0,
        html: true,
        actions: [
          {
            label: 'Change Password',
            handler: () => {
              onChangePassword()
            }
          },
          {
            label: 'Dismiss',
            color: 'red'
          }
        ]
      })
    }

    const onChangePassword = () => {
      router.push('/maintenance/change-password')
    }

    watch([() => store.state.menus, router.currentRoute], () => {
      const allowedUrls = store?.state?.menus?.map(item => `/${item?.url}`)
      const excludedUrls = excludedPaths
      const sourcePath = router?.currentRoute?.value?.path
      const lastIndex = sourcePath.lastIndexOf('/')
      const requiredPath =
        Object.keys(router?.currentRoute?.value?.params).length !== 0
          ? sourcePath.slice(0, lastIndex + 1).slice(0, -1)
          : sourcePath

      if (store.state.menus.length > 0) {
        if (allowedUrls?.length > 0) {
          if (
            !allowedUrls.includes(requiredPath) &&
            !excludedUrls.includes(requiredPath)
          ) {
            isAllowed.value = false
          } else {
            isAllowed.value = true
          }
        } else {
          if (excludedUrls.includes(requiredPath)) {
            isAllowed.value = true
          } else {
            isAllowed.value = false
          }
        }
      }
    })

    watch(isAllowed, () => {
      isLoadingAllowed.value = false
    })

    onBeforeMount(() => {
      provide('store', store)

      if (isAuthenticated.value) {
        get('v1/user/me')
      } else {
        isLoading.value = false
        router.push({ name: 'login' })
      }
    })

    onMounted(() => {
      watch([data, error, loading], () => {
        if (loading.value) {
          store.methods.updateUserAdmin(false)
          store.methods.updateUserProfile(null)
          store.methods.updateAccountType({
            principal: null,
            retailer: null,
            suki: null
          })
        } else if (!loading.value) {
          isLoading.value = false

          if (error?.value) {
            const route = router.resolve({
              name: 'login'
            })

            showToast('Session is invalid. redirecting to login...', 'danger')

            localStorage.removeItem('token')
            localStorage.removeItem('defaultID')
            window.location.assign(route.href)
          } else if (data?.value) {
            const expiryDate = dayjs(data?.value?.password_expiry_date)
            const diffDate = expiryDate.diff(dayjs(), 'day')
            const defaultID =
              data.value.account_type === 'principal'
                ? data?.value?.retailer_id
                : data.value.account_type === 'retailer'
                ? data?.value?.principal_id
                : null

            if (diffDate <= 5) {
              remainingDate.value = diffDate
              showNotif()
            }

            store.methods.updateUserAdmin(
              isAdminAccess.includes(parseInt(data?.value?.role_id))
            )
            store.methods.updateUserProfile(data?.value)
            store.methods.updateAccountType({
              principal: data.value.account_type === 'principal',
              retailer: data.value.account_type === 'retailer',
              suki: data.value.account_type === 'suki'
            })

            if (!localStorage.getItem('defaultID')) {
              store.methods.updateDefaultID(defaultID)
              localStorage.setItem('defaultID', defaultID)
            }
          }
        }
      })
    })

    return {
      isLoading,
      isLoadingAllowed,
      isAuthenticated,
      isAllowed,
      remainingDate
    }
  }
}
</script>

<style lang="scss" scoped>
.pageContainer {
  min-height: calc(100vh);
}

.pageContent {
  background-color: $grey-2;
  min-height: calc(100vh);
  padding: 10px 0 100px 0;
}
</style>
