export const SIDEBAR_MENU = {
  default: [],
  non_principal: [
    {
      id: 1,
      name: 'Campaign',
      icon: 'icon_campaign.png',
      url: '/campaign',
      links: [
        {
          id: 1,
          name: 'Campaign Lists',
          url: '/campaign/lists'
        }
      ]
    }
  ],
  principal: [
    {
      id: 1,
      name: 'Dashboard',
      icon: 'icon_company.png',
      url: '/dashboard'
    },
    {
      id: 2,
      name: 'Sales',
      icon: 'icon_company.png',
      url: '/sales',
      links: [
        {
          id: 1,
          name: 'By Store Report',
          url: '/sales/by-store-report'
        },
        {
          id: 2,
          name: 'Next Day Metrics',
          url: '/sales/next-day-metrics'
        },
        {
          id: 3,
          name: 'Monthly Metrics',
          url: '/sales/monthly-metrics'
        }
      ]
    },
    {
      id: 3,
      name: 'Product',
      icon: 'icon_products.png',
      url: '/product',
      links: [
        {
          id: 1,
          name: 'By Category Report',
          url: '/product/by-category-report'
        },
        {
          id: 2,
          name: 'By Brand Report',
          url: '/product/by-brand-report'
        },
        {
          id: 3,
          name: 'Product Analysis',
          url: '/product/analysis'
        }
      ]
    },
    {
      id: 4,
      name: 'Customer',
      icon: 'icon_customers.png',
      url: '/customer',
      links: [
        {
          id: 1,
          name: 'Customer Segment',
          url: '/customer/customer-segment'
        }
      ]
    },
    {
      id: 5,
      name: 'Campaign',
      icon: 'icon_campaign.png',
      url: '/campaign',
      links: [
        {
          id: 1,
          name: 'Create Campaign',
          url: '/campaign/create'
        },
        {
          id: 2,
          name: 'Campaign Lists',
          url: '/campaign/lists'
        },
        {
          id: 3,
          name: 'Campaign Results',
          url: '/campaign/results'
        }
      ]
    }
  ],
  retailer: [
    {
      id: 1,
      name: 'Sales',
      icon: 'icon_company.png',
      url: '/sales',
      links: [
        {
          id: 1,
          name: 'By Store Report',
          url: '/sales/by-store-report'
        },
        {
          id: 2,
          name: 'Next Day Metrics',
          url: '/sales/next-day-metrics'
        },
        {
          id: 3,
          name: 'Monthly Metrics',
          url: '/sales/monthly-metrics'
        }
      ]
    },
    {
      id: 2,
      name: 'Product',
      icon: 'icon_products.png',
      url: '/product',
      links: [
        {
          id: 1,
          name: 'By Category Report',
          url: '/product/by-category-report'
        },
        {
          id: 2,
          name: 'By Brand Report',
          url: '/product/by-brand-report'
        },
        {
          id: 3,
          name: 'Product Analysis',
          url: '/product/analysis'
        }
      ]
    },
    {
      id: 3,
      name: 'Campaign',
      icon: 'icon_campaign.png',
      url: '/campaign',
      links: [
        {
          id: 1,
          name: 'Campaign Lists',
          url: '/campaign/lists'
        }
      ]
    }
  ]
}
