import dayjs from 'dayjs'

export const toFriendlyDateTime = date => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}

export const toFriendlyDate = (date, delimeter) => {
  return dayjs(date).format(`YYYY${delimeter ?? '-'}MM${delimeter ?? '-'}DD`)
}

export const toFriendlyTime = date => {
  return dayjs(date).format('HH:mm:ss')
}

export const toFriendlyTime12hr = date => {
  return dayjs(date).format('HH:mm:ss')
}

export const toFriendlyYear = date => {
  return dayjs(date).format('YYYY')
}

export const toFriendlyMonthYear = (date, delimeter) => {
  return dayjs(date).format(`YYYY${delimeter ?? ' - '}MM`)
}

export const toFriendlyFirstDay = (date, type) => {
  return dayjs(date).startOf(type ?? 'month')
}

export const toFriendlyLastDay = (date, type) => {
  return dayjs(date).endOf(type ?? 'month')
}

export const addDays = (date, duration, type) => {
  return dayjs(date).add(duration, type ?? 'days')
}

export const subtractDays = (date, duration, type) => {
  return dayjs(date).subtract(duration, type ?? 'days')
}

export const toFriendlyMonthName = date => {
  return dayjs(date).format('MMM')
}

export const toFriendlyMonthYearName = date => {
  return dayjs(date).format('YYYY-MMM')
}
