import { FORMAT } from '@/tools'

export * as HELP from './help'
export * as MENU from './menu'

export const excludedPaths = ['/dashboard', '/maintenance/change-password']

export const isAdminAccess = [2, 3, 7]

export const optionsTop = [
  {
    label: '20',
    value: 20
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '500',
    value: 500
  }
]

export const optionsSortBy = [
  {
    label: 'Sales',
    value: 'sales'
  },
  {
    label: 'Transaction Count',
    value: 'trx_count'
  }
]

export const optionsLevel = [
  {
    label: 'Category',
    value: 'category'
  },
  {
    label: 'Brand',
    value: 'brand'
  },
  {
    label: 'SKU',
    value: 'sku'
  }
]

export const optionsLevelID = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Category',
    value: 'category_id'
  },
  {
    label: 'Brand',
    value: 'brand_id'
  },
  {
    label: 'SKU',
    value: 'sku_id'
  }
]

export const optionsCompare = [
  {
    label: 'Year Ago',
    value: 'Year'
  },
  {
    label: 'Month Ago',
    value: 'Month'
  }
]

export const dashboardWidgets = [
  {
    name: 'Monthly Sales',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth ${FORMAT.toPercent(0)}`,
    variant: 'primary'
  },
  {
    name: 'Monthly Transactions',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth ${FORMAT.toPercent(0)}`,
    variant: 'primary'
  },
  {
    name: 'Monthly Basket Size',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'primary'
  },
  {
    name: 'Monthly Frequency',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toNumber(0)}`,
    variant: 'primary'
  },
  {
    name: 'Unique Customers (P1)',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'primary'
  },
  {
    name: 'Unique Customers (P3)',
    value: FORMAT.toNumber(0),
    subValue: `YA Same Month: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'primary'
  },
  {
    name: 'YTD Sales',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toCurrency(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'YTD Transactions',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'YTD Basket Size',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'YTD Frequency',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'YTD SKUs/Basket',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'YTD Unique Customers',
    value: FORMAT.toNumber(0),
    subValue: `YA: ${FORMAT.toNumber(0)}`,
    subTitle: `Growth: ${FORMAT.toPercent(0)}`,
    variant: 'secondary'
  },
  {
    name: 'Share of Sales',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  },
  {
    name: 'Share of Transactions',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  },
  {
    name: 'Share of Basket Size',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  },
  {
    name: 'Share of YTD SKUs/Basket',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  },
  {
    name: 'Share of Customers (P1)',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  },
  {
    name: 'Share of Customers (P3)',
    value: FORMAT.toPercent(0),
    subTitle: 'of total MySuki',
    variant: 'info'
  }
]

export const optionsBought = [
  {
    label: 'Bought',
    value: true
  },
  {
    label: 'Did Not Buy',
    value: false
  }
]

export const optionsLeastMost = [
  {
    label: 'At Least',
    value: 'at-least'
  },
  {
    label: 'At Most',
    value: 'at-most'
  },
  {
    label: 'In Between',
    value: 'in-between'
  }
]

export const optionsSend = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
]

export const optionsMyNonCustomers = [
  {
    label: 'My Customers',
    value: 'T'
  },
  {
    label: 'Non-Customers',
    value: 'F'
  }
]

export const campaign = [
  {
    name: 'all',
    label: 'All',
    value: 'all',
    icon: 'list',
    count: 0,
    color: 'info',
    action: 'all',
    tab: true,
    enabled: false
  },
  {
    name: 'draft',
    label: 'Draft',
    value: 'F',
    icon: 'drafts',
    count: 0,
    color: 'info',
    action: 'draft',
    tab: true,
    enabled: false
  },
  {
    name: 'requested',
    label: 'Requested',
    value: 'Q',
    icon: 'alarm',
    count: 0,
    color: 'warning',
    action: 'request',
    tab: true,
    enabled: false
  },
  {
    name: 'rejected',
    label: 'Rejected',
    value: 'R',
    icon: 'thumb_down_alt',
    count: 0,
    color: 'danger',
    action: 'reject',
    tab: true,
    enabled: false
  },
  {
    name: 'approved',
    label: 'Approved',
    value: 'P',
    icon: 'thumb_up_alt',
    count: 0,
    color: 'success',
    action: 'approve',
    tab: true,
    enabled: false
  },
  {
    name: 'setup',
    label: 'Setup',
    value: 'S',
    icon: 'read_more',
    count: 0,
    color: 'success',
    action: 'setup',
    tab: true,
    enabled: false
  },
  {
    name: 'active',
    label: 'Active',
    value: 'A',
    icon: 'campaign',
    count: 0,
    color: 'success',
    action: 'active',
    tab: true,
    enabled: false
  },
  {
    name: 'completed',
    label: 'Completed',
    value: 'C',
    icon: 'domain_verification',
    count: 0,
    color: 'success',
    action: 'complete',
    tab: true,
    enabled: false
  },
  {
    name: 'deleted',
    label: 'Deleted',
    value: 'D',
    icon: 'delete',
    count: 0,
    color: 'danger',
    action: 'delete',
    tab: false,
    enabled: false
  },
  {
    name: 'voided',
    label: 'Voided',
    value: 'V',
    icon: 'block',
    count: 0,
    color: 'danger',
    action: 'void',
    tab: false,
    enabled: false
  },
  {
    name: 'cancelled',
    label: 'Cancelled',
    value: 'C',
    icon: 'block',
    count: 0,
    color: 'danger',
    action: 'cancel',
    tab: false,
    enabled: false
  }
]

export const campaignType = {
  principal: [
    'draft',
    'requested',
    'rejected',
    'approved',
    'setup',
    'active',
    'completed'
  ],
  retailer: [
    'requested',
    'rejected',
    'approved',
    'setup',
    'active',
    'completed'
  ],
  suki: ['approved', 'setup', 'active', 'completed'],
  results: ['all', 'setup', 'active', 'completed']
}
