<template>
  <q-drawer
    v-model="sidebar"
    show-if-above
    :mini="true"
    :mini-width="130"
    bordered
  >
    <div class="q-pa-sm row items-center justify-center">
      <q-item router :to="{ name: 'dashboard' }">
        <img src="@/assets/images/logo_retina.png" width="120" />
      </q-item>
    </div>

    <q-list>
      <div v-for="menu in menus" :key="menu.id" class="cursor-pointer">
        <q-expansion-item
          :header-class="screenSmall ? 'menu' : 'menu big'"
          :id="`menu_${menu.id}`"
          v-if="menu?.links?.length > 0"
          v-model="expansionState[menu.id]"
          @click="toggleClickMenu(menu.id)"
          @mouseover="toggleOpenMenu($event, menu.id)"
          @mouseleave="toggleCloseMenu(menu.id)"
        >
          <template v-slot:header>
            <q-item-section avatar v-if="screenSmall">
              <div class="column items-center justify-center">
                <img :src="getIconUrl(menu.icon)" width="30" />
              </div>
            </q-item-section>
            <q-item-section v-if="screenSmall">
              <span class="menu-item">{{ menu.name }}</span>
            </q-item-section>

            <q-item-section avatar v-else>
              <div class="column items-center justify-center">
                <img :src="getIconUrl(menu.icon)" width="30" />
                <span class="menu-item">{{ menu.name }}</span>
              </div>
            </q-item-section>
          </template>

          <q-list :bordered="!screenSmall" padding class="submenu">
            <q-item
              class="menu"
              clickable
              v-ripple
              v-for="submenu in menu.links"
              :key="submenu.id"
              router
              :to="submenu.url"
              @click="!screenSmall ? (expansionState[menu.id] = false) : ''"
            >
              <q-item-section avatar>
                <span class="gt-sm menu-item">{{ submenu.name }}</span>
              </q-item-section>

              <q-item-section class="menu-item">
                {{ submenu.name }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-expansion-item>

        <q-item
          :class="screenSmall ? 'menu' : 'menu big'"
          clickable
          v-ripple
          v-else
          router
          :to="menu.url"
        >
          <q-item-section avatar>
            <div class="column items-center justify-center">
              <img :src="getIconUrl(menu.icon)" width="30" />
              <span class="gt-sm menu-item">{{ menu.name }}</span>
            </div>
          </q-item-section>

          <q-item-section class="menu-item">
            {{ menu.name }}
          </q-item-section>
        </q-item>
      </div>
    </q-list>
  </q-drawer>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { Screen, useQuasar } from 'quasar'

import { Toast, fetchData } from '@/tools'
import store from '@/store'
import { MENU } from '@/constants'

export default {
  name: 'Sidebar',
  props: ['sidebarOpen'],
  setup() {
    const quasar = useQuasar()
    const { showToast } = Toast()
    const { data, error, loading, get } = fetchData()
    const state = store?.state

    const sidebar = ref(false)
    const screenSmall = ref(Screen.lt.md)
    const expansionState = ref({})
    const sidebarRef = ref(null)
    const sidebarTop = ref(0)
    const sidebarContent = ref(null)
    const menus = ref(MENU.SIDEBAR_MENU.default)
    const isPrincipal = ref(state?.accountType?.principal)
    const isRetailer = ref(state?.accountType?.retailer)
    const isSuki = ref(state?.accountType?.suki)
    const isRunned = ref(false)
    const isAdmin = ref(state?.isAdmin)

    const getIconUrl = icon => {
      var images = require.context('../assets/images/')
      return images('./' + icon)
    }

    const toggleSidebar = () => {
      sidebar.value = !sidebar.value
    }

    const checkScreen = () => {
      if (Screen.gt.sm) {
        screenSmall.value = false
      } else {
        screenSmall.value = true
      }
    }

    const toggleOpenMenu = (e, id) => {
      if (!screenSmall.value) {
        const element = document.getElementById(`menu_${id}`)

        expansionState.value[id] = true
        sidebarTop.value = element.offsetTop
        sidebarContent.value = element.querySelector(
          '.q-expansion-item__content'
        )

        if (sidebarContent.value) {
          sidebarContent.value.style.top = `${sidebarTop.value}px`
        }
      }
    }

    watch(sidebarTop, () => {
      if (sidebarContent.value) {
        sidebarContent.value.style.top = `${sidebarTop.value}px`
      }
    })

    const toggleCloseMenu = id => {
      if (!screenSmall.value) {
        expansionState.value[id] = false
      }
    }

    const toggleClickMenu = id => {
      if (!screenSmall.value) {
        expansionState.value[id] = !expansionState.value[id]
      }
    }

    onBeforeMount(() => {
      get('v1/user/modules')

      watch([data, error, loading], () => {
        if (!loading.value) {
          store.methods.updateMenus([])

          // TEMPORARY MENU
          if (
            (isRetailer.value || isSuki.value || !data.value) &&
            !isRunned.value
          ) {
            data.value = ['temp']
            isRunned.value = true
          }
          // TEMPORARY MENU

          if (error?.value) {
            showToast('There was a problem fetching your menu.', 'danger')
          } else if (data?.value) {
            // TEMPORARY MENU
            let menuURLs = []

            if (isPrincipal.value) {
              menus.value = MENU.SIDEBAR_MENU.principal
            } else if (isRetailer.value) {
              menus.value = MENU.SIDEBAR_MENU.retailer
            } else if (isSuki.value) {
              menus.value = MENU.SIDEBAR_MENU.non_principal
            } else {
              menus.value = MENU.SIDEBAR_MENU.default
            }

            menus.value.forEach(item => {
              menuURLs.push({ url: item.url.substring(1) })
              if (item?.links) {
                item.links.forEach(link =>
                  menuURLs.push({ url: link.url.substring(1) })
                )
              }
            })

            if (isPrincipal.value) {
              menuURLs.push({ url: 'campaign/update' })
              menuURLs.push({ url: 'customer/customer-segment/create' })
              menuURLs.push({ url: 'customer/customer-segment/update' })
            }

            if (isAdmin.value) {
              menuURLs.push({ url: 'maintenance/users' })
            }
            // END TEMPORARY MENU

            store.methods.updateMenus(menuURLs)

            const groupedMenu = data?.value
              ?.filter(item => {
                if (item.menu_tag === 'Y') {
                  return true
                }
                return false
              })
              .map(item => {
                return {
                  firstLvl: item?.firstLvl,
                  name: item?.module,
                  icon: item?.icon
                }
              })

            const firstLvl = [
              ...new Map(
                groupedMenu.map(item => [item.firstLvl, item])
              ).values()
            ]

            const secondLvl = data?.value?.filter(item => {
              if (item.menu_tag === 'Y') {
                if (item.secondLvl != '0' && item.thirdLvl == '0') {
                  return true
                }
              }
              return false
            })

            firstLvl
              .map(item => {
                return {
                  id: item?.firstLvl,
                  name: item?.name,
                  icon: item?.icon ?? 'icon_company.png',
                  url: `/${item?.url}`,
                  links: secondLvl
                    ?.filter(filter2 => filter2.firstLvl === item?.firstLvl)
                    .map(item2 => {
                      return {
                        id: item2?.secondLvl,
                        name: item2?.name,
                        url: `/${item2?.url}`
                      }
                    })
                }
              })
              .sort((a, b) => {
                let id_a = a.id
                let id_b = b.id
                if (id_a < id_b) {
                  return -1
                } else if (id_a > id_b) {
                  return 1
                }
                return 0
              })
          }
        }
      })
    })

    return {
      quasar,
      sidebar,
      screenSmall,
      expansionState,
      sidebarRef,
      sidebarTop,
      menus,
      getIconUrl,
      toggleSidebar,
      checkScreen,
      toggleOpenMenu,
      toggleCloseMenu,
      toggleClickMenu
    }
  },
  watch: {
    sidebarOpen() {
      this.toggleSidebar()
    },
    'quasar.screen.width'() {
      this.checkScreen()
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.menu) {
  border-radius: 0 20px 20px 0;
  margin: 8px 12px 8px 0;
  font-weight: 600;
  color: #2e4faa;
  overflow: hidden;
  cursor: pointer;

  &.big {
    height: 65px;
  }

  &.q-router-link--active {
    background-color: #f8f8f8;
  }
}

:deep(.menu-item) {
  font-size: 0.75rem;
  color: #2e4faa;
  font-weight: 600;
}

:deep(.q-drawer--mini) {
  .q-expansion-item--expanded {
    background-color: #f8f8f8;

    .q-expansion-item__content {
      border-radius: 0 20px 20px 0;
      background-color: white;
      display: block !important;
      position: fixed;
      left: 130px;
      width: 200px;
    }
  }
}

:deep(.submenu) {
  border-radius: 0 20px 20px 0;

  .q-item {
    padding-left: 20px !important;
    justify-content: left !important;
  }
}
</style>
