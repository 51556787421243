import { createRouter, createWebHistory } from 'vue-router'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerBall from 'quasar/src/components/spinner/QSpinnerBall.js';

const routes = [
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/PageNotFound')
  },

  {
    path: '/',
    name: 'index',
    component: () => import('../views/Landing')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/account/Login')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/sales/Sales'),
    children: [
      {
        path: 'by-store-report',
        component: () => import('../views/sales/ByStoreReport')
      },
      {
        path: 'p60-daily-trend',
        component: () => import('../views/sales/P60DailyTrend')
      },
      {
        path: 'next-day-metrics',
        component: () => import('../views/sales/NextDayMetrics')
      },
      {
        path: 'monthly-metrics',
        component: () => import('../views/sales/MonthlyMetrics')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/Product'),
    children: [
      {
        path: 'analysis',
        component: () => import('../views/product/ProductAnalysis')
      },
      {
        path: 'by-category-report',
        component: () => import('../views/product/ByCategoryReport')
      },
      {
        path: 'by-brand-report',
        component: () => import('../views/product/ByBrandReport')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('../views/customer/Customer'),
    children: [
      {
        name: 'customer_segment_list',
        path: 'customer-segment',
        component: () => import('../views/customer/CustomerSegment')
      },
      {
        name: 'customer_segment_create',
        path: 'customer-segment/create',
        component: () => import('../views/customer/CustomerSegmentCreate')
      },
      {
        name: 'customer_segment_update',
        path: 'customer-segment/update/:id',
        component: () => import('../views/customer/CustomerSegmentCreate')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('../views/campaign/Campaign'),
    children: [
      {
        name: 'campaign_lists',
        path: 'lists',
        component: () => import('../views/campaign/CampaignLists')
      },
      {
        name: 'campaign_lists_tab',
        path: 'lists/:tab',
        component: () => import('../views/campaign/CampaignLists')
      },
      {
        name: 'campaign_create',
        path: 'create',
        component: () => import('../views/campaign/CampaignCreate')
      },
      {
        name: 'campaign_update',
        path: 'update/:id',
        component: () => import('../views/campaign/CampaignCreate')
      },
      {
        name: 'campaign_results',
        path: 'results',
        component: () => import('../views/campaign/CampaignResults')
      },
      {
        name: 'campaign_results_tab',
        path: 'results/:tab',
        component: () => import('../views/campaign/CampaignResults')
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/maintenance/Maintenance'),
    children: [
      {
        path: 'change-password',
        component: () =>
          import('../views/maintenance/ChangePasswordMaintenance')
      },
      {
        path: 'users',
        component: () => import('../views/maintenance/UserMaintenance')
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token')

  if (to.name) {
    Loading.show({
      spinner: QSpinnerBall
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next()
    } else {
      next('/login')
    }
  } else {
    const excludeAuth = ['index', 'login']

    if (isAuthenticated && excludeAuth.includes(to.name)) {
      next('/dashboard')
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  Loading.hide()
})

export default router
