import { reactive, readonly } from 'vue'

const state = reactive({
  isAuthenticated: localStorage.getItem('token'),
  defaultID: localStorage.getItem('defaultID'),
  isAdmin: false,
  profile: null,
  menus: [],
  accountType: {
    principal: null,
    retailer: null,
    suki: null
  },
  previousTab: null
})

const methods = {
  updateUserAdmin(data) {
    state.isAdmin = data
  },
  updateUserProfile(data) {
    state.profile = data
  },
  updateMenus(data) {
    state.menus = data
  },
  updateAccountType(data) {
    state.accountType = data
  },
  updatePreviousTab(data) {
    state.previousTab = data
  },
  updateDefaultID(data) {
    state.defaultID = data
  }
}

const getters = {}

export default {
  state: readonly(state),
  methods,
  getters
}
