export const TABLE = [
  {
    name: 'name',
    label: 'Name',
    field: 'name',
    align: 'left',
    sortable: false
  },
  {
    name: 'description',
    label: 'Description',
    field: 'description',
    align: 'left',
    sortable: false
  }
]

export const DASHBOARD = {
  description:
    'All metrics shown in this report is limited to the logged in principal/supplier and the chosen retailer.',
  terms: [
    {
      name: 'Monthly Basket Size',
      description:
        'Average Peso basket size for the month.  Calculated as Monthly Sales / Monthly Transactions'
    },
    {
      name: 'Monthly Frequency',
      description:
        'The average number of times that each unique customer buys during the month.  Calculated as Monthly Transactions / Unique Customers (P1)'
    },
    {
      name: 'Monthly Transactions',
      description: 'Total count of transactions/invoices for the selected month'
    },
    {
      name: 'Share of Basket Size',
      description:
        'Monthly Basket Size of the Principal divided by the Monthly Basket Size of Retailer in MySuki'
    },
    {
      name: 'Share of Customers (P1)',
      description:
        '% of customers who bought at least one item of the Principal in the selected month'
    },
    {
      name: 'Share of Customers (P3)',
      description:
        '% of customers in the past 3 months (inclusive of selected month) who bought at least one item of the Principal'
    },
    {
      name: 'Share of Sales',
      description:
        'The principals % of sales compared to total MySuki Sales of the retailer. Calculated for the selected month.'
    },
    {
      name: 'Share of Transactions',
      description:
        'The count of transactions that had at least one SKU of the principal - divided by the total transactions in MySuki.  Calculated for the selected month.'
    },
    {
      name: 'Share of YTD SKUs/Basket',
      description:
        'YTD SKUs/Basket of the Principal - divided by Average YTD SKUs / Basket of all MySuki orders.'
    },
    {
      name: 'Unique Customers (P1)',
      description:
        'The count of unique customers who bought in the selected month'
    },
    {
      name: 'Unique Customers (P3)',
      description:
        'The count of unique customers who bought in past 3 months inclusive of the selected month.  i.e. if Selected Month is February 2022, this metric will count the unique customers who purchased from Dec 2021 to Feb 2022.'
    },
    {
      name: 'YTD Basket Size',
      description:
        'Average Peso basket size.  Calculated as YTD Sales / YTD Transactions'
    },
    {
      name: 'YTD Frequency',
      description:
        'The average number of times that each unique customer buys during the selected YTD period.  Calculated as YTD Transactions / YTD Unique Customers'
    },
    {
      name: 'YTD Sales',
      description: 'Total sales from Jan 1 to selected month'
    },
    {
      name: 'YTD SKUs/Basket',
      description:
        'Average number of unique SKUs per transaction for the selected YTD period'
    },
    {
      name: 'YTD Transactions',
      description:
        'Total count of transactions/invoices from Jan 1 to selected month'
    },
    {
      name: 'YTD Unique Customers',
      description:
        'The count of unique customers who bought in the selected YTD period'
    }
  ]
}

export const BY_STORE_REPORT = {
  description:
    'This report shows the store metrics for the specified date range, store, category and brand',
  terms: [
    {
      name: 'Basket Size',
      description:
        'Average Peso basket size for the store in the specified period. Calculated as Sales / Transaction Count.'
    },
    {
      name: 'Category',
      description:
        'Categorization based on principal/manufacturer standards, not retailer standards.'
    },
    {
      name: 'Quantity',
      description:
        'Total items purchased for the store in the specified period.  Note that this is not unique products - if a shopper bought 3 apples and 2 oranges, this metric will show 5.'
    },
    {
      name: 'Sales',
      description: 'Total sales for the store in the specified period.'
    },
    {
      name: 'Store',
      description: 'Name of Store'
    },
    {
      name: 'Transaction Count',
      description:
        'Total count of transactions or invoices for the store in the specified period.'
    }
  ]
}

export const NEXT_DAY_METRICS = {
  description:
    'This report shows the key metrics for the specified date range, store, and sku hierarchy.',
  terms: [
    {
      name: 'Basket Size',
      description:
        'Average Peso basket size for the specified filters. Calculated as Sales / Transaction Count.'
    },
    {
      name: 'Business Date',
      description: 'Date when the order was transacted.'
    },
    {
      name: 'Daily Sales Trend',
      description:
        'A graph depicting the sales per day for the specified filters.'
    },
    {
      name: 'Frequency',
      description:
        'Average times each unique customer vsisited during that period.  Calculated as Transaction Count / Unique Customers.'
    },
    {
      name: 'Sales',
      description: 'Total sales for specified filters.'
    },
    {
      name: 'Transaction Count',
      description:
        'Total count of transactions or invoices for the specified filters.'
    },
    {
      name: 'Unique Customers',
      description:
        'Count of unique customers who made at least one purchase for the specified filters.'
    },
    {
      name: 'Unique SKUs',
      description:
        'Count of unique SKUs purchased for the specified filters.  If shoppers bought 3 apples and 5 oranges, Unique SKU count is 2.'
    }
  ]
}

export const BY_CATEGORY_REPORT = {
  description:
    'This report gives key metrics per category on a monthly view.  It also provides comparison to same month year ago or versus the previous month.',
  terms: [
    {
      name: 'Basket Penetration',
      description:
        '% of baskets that contain at least one (1) item from that category versus all baskets of that retailer for the specified filters'
    },
    {
      name: 'Basket Size',
      description:
        'Average Peso basket size for the specified filters. Calculated as Sales / Transaction Count.'
    },
    {
      name: 'Category Name',
      description:
        'Categorization based on principal/manufacturer standards, not retailer standards.'
    },
    {
      name: 'Customers (P1) ',
      description:
        'Count of unique customers in the last 1 month who bought at least 1 item from that category'
    },
    {
      name: 'Customers (P3)',
      description:
        'Count of unique customers in the last 3 months who bought at least 1 item from that category'
    },
    {
      name: 'Gain/Loss',
      description:
        '% increase or decrease in sales for that category for the current month versus previous reporting period'
    },
    {
      name: 'Gainers & Losers (Chart)',
      description:
        'Visual ranking of the net sales change of each category - comparing current period to another period.  Sorted from highest increase (left) to biggest decrease (right).'
    },
    {
      name: 'Sales',
      description: 'Total sales for the category in the specified period.'
    },
    {
      name: 'Sales Trend',
      description:
        'Chart visualizing the monthly sales movement of that category in the past 8 months'
    },
    {
      name: 'Top Category Sales (Chart)',
      description:
        'Visual Ranking of the categories, sorted based from highest sales for the period to the lowest'
    },
    {
      name: 'Transaction Count',
      description:
        'Total count of transactions or invoices for the specified filters.'
    }
  ]
}

export const BY_BRAND_REPORT = {
  description:
    'This report gives key metrics per brand on a monthly view.  It also provides comparison to same month year ago or versus the previous month.',
  terms: [
    {
      name: 'Basket Penetration',
      description:
        '% of baskets that contain at least one (1) item from that brand versus all baskets of that retailer for the specified filters'
    },
    {
      name: 'Basket Size',
      description:
        'Average Peso basket size for the specified filters. Calculated as Sales / Transaction Count.'
    },
    {
      name: 'Customers (P1) ',
      description:
        'Count of unique customers in the last 1 month who bought at least 1 item from that brand'
    },
    {
      name: 'Customers (P3)',
      description:
        'Count of unique customers in the last 3 months who bought at least 1 item from that brand'
    },
    {
      name: 'Gain/Loss',
      description:
        '% increase or decrease in sales for that brand for the current month versus previous reporting period'
    },
    {
      name: 'Gainers & Losers (Chart)',
      description:
        'Visual ranking of the net sales change of each brand - comparing current period to another period.  Sorted from highest increase (left) to biggest decrease (right).'
    },
    {
      name: 'Sales',
      description: 'Total sales for the brand in the specified period.'
    },
    {
      name: 'Top Category Sales (Chart)',
      description:
        'Visual Ranking of the brands, sorted based from highest sales for the period to the lowest'
    },
    {
      name: 'Transaction Count',
      description:
        'Total count of transactions or invoices for the specified filters.'
    }
  ]
}

export const PRODUCT_ANALYSIS = {
  description: 'This report gives key metrics per SKU on a monthly view.',
  terms: [
    {
      name: 'Barcode',
      description: 'UPC or EAN code of the product'
    },
    {
      name: 'Category Name',
      description:
        'Categorization based on principal/manufacturer standards, not retailer standards.'
    },
    {
      name: 'Quantity',
      description:
        'Total items purchased for that product for the specified period'
    },
    {
      name: 'Rank (Cat)',
      description:
        'Ranking of that product based on sales versus other products of the same category'
    },
    {
      name: 'Sales',
      description: 'Total sales for the product in the specified period.'
    },
    {
      name: 'Sales YTD',
      description:
        'Total sales for the product from January 1 of the selected year up to the specified period.'
    },
    {
      name: 'Top 20 SKU (Sales)',
      description:
        'Visual Ranking of the products, sorted based from highest sales for the period to the lowest'
    },
    {
      name: 'Top 20 SKU (Transaction Count)',
      description:
        'Visual Ranking of the products, sorted based from highest transaction count for the period to the lowest'
    },
    {
      name: 'Total Count Rank (All)',
      description: 'Ranking of that product based on sales across all products'
    },
    {
      name: 'Unique Customers',
      description:
        'Count of unique customers who made at least one purchase for the specified filters.'
    }
  ]
}

export const CAMPAIGN_LISTS = {
  description:
    'This view lists down all the promotional campaigns that a selected principal wants to run.  ',
  status: {
    description:
      'A campaign can have a status from any of these seven options:',
    lists: [
      {
        name: 'Draft',
        description:
          'Used when a principal has created/saved a campaign but has not yet submitted the campaign for approval of retailer.'
      },
      {
        name: 'Requested',
        description:
          'Used when the principal has already submitted a campaign to retailer for approval.  Retailer is automatically informed when principal submits a request.'
      },
      {
        name: 'Rejected',
        description:
          'Used when retailer rejects the principal request.  A valid reason must be input by the retailer in case of rejection.'
      },
      {
        name: 'Approved',
        description:
          'Used when a retailer has already approved the request of the principal - but the campaign is pending setup in MySuki Hub.'
      },
      {
        name: 'Setup',
        description:
          'Used when the campaign has already been set up in MySuki Hub - but the campaign has not yet entered its active duration.'
      },
      {
        name: 'Active',
        description:
          'Used when an approved campaign is now used on a currently active promotional campaign.'
      },
      {
        name: 'Completed',
        description: `Used when an approved campaign has completed it's promotional campaign duration.`
      }
    ]
  },
  create: {
    description: 'To create a new campaign:',
    lists: [
      {
        id: 1,
        text: 'Click on Create New Button'
      },
      {
        id: 2,
        text: 'Input a campaign code (up to 20 characters)'
      },
      {
        id: 3,
        text: 'Input a campaign name (longer description of campaign), the duration of promotional campaign, and the mechanics of the campaign.'
      },
      {
        id: 4,
        text: 'Select the customer segment created via the Customer Segment Module.'
      },
      {
        id: 5,
        text: 'Attach the trade letter.  The expectation is that this trade letter has been pre-discussed with the retailer before campaign is setup in Retina.'
      },
      {
        id: 6,
        text: 'If campain includes a notification to the shopper, select YES in notification setting.  Input the notification message to be sent out.'
      },
      {
        id: 7,
        text: 'Click on SAVE DRAFT or when ready, SUBMIT REQUEST.'
      }
    ]
  },
  manage: {
    description: 'To manage requested campaigns:',
    lists: [
      {
        id: 1,
        text: 'Go the the requested tab'
      },
      {
        id: 2,
        text: 'Requests can be viewed, rejected, or approved by clicking on the kebab icon on the right hand side of each campaign'
      },
      {
        id: 3,
        text: 'If campaign request is to be rejected, a reason note must be input by the retailer.'
      }
    ]
  },
  other: {
    description: 'Other notes:',
    lists: [
      {
        id: 1,
        text: 'Requested campaign that are not yet approved can still be edited by the principal'
      },
      {
        id: 2,
        text: 'Once a campaign has been approved, it can no longer be edited by the principal'
      }
    ]
  }
}

export const CAMPAIGN_CREATE = {
  description: 'Allows you the create a campaign for your retailer.',
  create: {
    description: 'To create a new campaign:',
    lists: [
      {
        id: 1,
        text: 'Input a campaign code (up to 20 characters)'
      },
      {
        id: 2,
        text: 'Input a campaign name (longer description of campaign), the duration of promotional campaign, and the mechanics of the campaign.'
      },
      {
        id: 3,
        text: 'Select the customer segment created via the Customer Segment Module.'
      },
      {
        id: 4,
        text: 'Attach the trade letter.  The expectation is that this trade letter has been pre-discussed with the retailer before campaign is setup in Retina.'
      },
      {
        id: 5,
        text: 'If campain includes a notification to the shopper, select YES in notification setting.  Input the notification message to be sent out.'
      },
      {
        id: 6,
        text: 'Click on SAVE DRAFT or when ready, SUBMIT REQUEST.'
      }
    ]
  }
}

export const CUSTOMER_SEGMENT = {
  description:
    'This view lists down all the custom created customer segment that a selected principal wants to run.',
  create: {
    description: 'To create a new customer segment:',
    lists: [
      {
        id: 1,
        text: 'Input a segment code (up to 20 characters)'
      },
      {
        id: 2,
        text: 'Input a segment name and remarks to help identify what the segment is for'
      },
      {
        id: 3,
        text: 'Input a date range for the Purchase Time Frame - this filter will only include the customers who bought at least once within this time frame.   '
      },
      {
        id: 4,
        text: 'If you need to look for the customers who DID NOT BUY within a specificied time frame, click on the + button on the right hand side and input the parameters in the DID NOT BUY TIMEFRAME.'
      },
      {
        id: 5,
        text: 'Select the stores you want to include - you may select ALL stores.'
      },
      {
        id: 6,
        text: 'Filter based on products - you can filter based on purchase or non-purchase of a specific product.  This is optional.'
      },
      {
        id: 7,
        text: 'You can also filter based on brand or category similar to products'
      },
      {
        id: 8,
        text: 'Filter customers based on their basket size.  This is optional.'
      },
      {
        id: 9,
        text: 'Filter customers based on the number of times they have purchased on the time frame indicated.  This is optional.'
      },
      {
        id: 10,
        text: 'Click on the ESTIMATE button on the header menu.  A count of customers meeting that selection will be shown.'
      },
      {
        id: 11,
        text: 'If happy with the segment created, click on SAVE SEGMENT.'
      }
    ]
  }
}

export const CAMPAIGN_RESULTS = {
  description:
    'This view lists down the counts of all the promotional campaign vouchers',
  lists: [
    {
      name: 'Reach',
      description:
        'Count of unique shoppers who ordered during the promo period'
    },
    {
      name: 'Claimed',
      description: 'Count of unique shoppers who used the promo voucher'
    },
    {
      name: 'Unclaimed',
      description: 'Count of unique shoppers who did not use the promo voucher'
    },
    {
      name: 'Conversion Rate',
      description: '% of Claimed / Reach'
    }
  ]
}
